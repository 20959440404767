import {ExtPromise} from "./ExtPromise";

export function extFetch(url: string, options?: RequestInit): ExtPromise<Response> {
    return new ExtPromise<Response>((resolve, reject) => {
        fetch(url, options)
            .then(resolve)
            .catch(reject);
    });
}

export function extFetchMock<T>(data: T): ExtPromise<T> {
    return new ExtPromise<T>((resolve, reject) => {
        resolve(data)
    })
}
