import {UserRole} from "@anon/core/network/client/auth/model/UserRole";

export class UserNetworkModel {

    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly surname: string,
        public readonly role: UserRole,
        public readonly patronymic?: string,
        public readonly avatarUrl?: string,
    ) {
    }
}