import {SignOutRepository} from "@app/feature/sign-out/data/SignOutRepository";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {SignOutNetworkClient} from "@app/feature/sign-out/data/network/SignOutNetworkClient";
import {SignOutNetworkClientImpl} from "@app/feature/sign-out/data/network/SignOutNetworkClientImpl";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";

export class SignOutRepositoryImpl implements SignOutRepository {


    constructor(
        private readonly networkClient: SignOutNetworkClient = new SignOutNetworkClientImpl(),
        private readonly useStorage: UserStorage = new UserStorageImpl()
    ) {
    }

    signOut = (): ExtPromise<any> =>
        new ExtPromise<any>((resolve, reject)=>{
            resolve({})
        }).onEach(() => this.useStorage.clearAll())
        // this.networkClient.signOut()
        //     .onEach(() => this.useStorage.clearAll())

}