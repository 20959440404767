import {AuthNetworkClient} from "@anon/feature/auth/data/network/AuthNetworkClient";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {NetworkProvider} from "@anon/core/network/NetworkProvider";
import {NetworkProviderImpl} from "@anon/core/network/NetworkProviderImpl";
import {SignInNetworkModel} from "@anon/feature/auth/data/network/model/SignInNetworkModel";
import {signInResponse_toNetworkModel} from "@anon/feature/auth/data/DataMapper";

export class AuthNetworkClientImpl implements AuthNetworkClient {


    constructor(
        private networkProvider: NetworkProvider = new NetworkProviderImpl()
    ) {
    }

    auth = (login: string, password: string): ExtPromise<SignInNetworkModel> =>
        this.networkProvider.getAuthClient()
            .signIn(login, password)
            .then(it => signInResponse_toNetworkModel(it))

}