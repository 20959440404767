import {UserNetworkModel} from "@anon/feature/auth/data/network/model/UserNetworkModel";
import {TokenNetworkModel} from "@anon/feature/auth/data/network/model/TokenNetworkModel";

export class SignInNetworkModel {

    constructor(
        public readonly user: UserNetworkModel,
        public readonly token: TokenNetworkModel,
    ) {
    }
}