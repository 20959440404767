import {IconMenu} from "@core/ui/icon/IconMenu";

interface Props {
    onClick?: () => void
}

export function ButtonToolbarMenu(props: Props) {
    return (
        <div className="flex cursor-pointer p-2 items-center rounded-full hover:bg-gray-50 active:bg-gray-100"
             onClick={props.onClick}>
            <IconMenu/>
        </div>
    )
}