import {Route, Routes} from "react-router-dom";
import {VideoListPage} from "@admin/feature/video/ui/page/VideoListPage";
import {VideoPage} from "@admin/feature/video/ui/page/VideoPage";

export function VideoFeature() {

    return (
        <Routes>
            <Route path="/" element={<VideoListPage/>}/>
            <Route path=":id" element={<VideoPage/>}/>
        </Routes>
    )
}