import {IconChange} from "@core/ui/icon/IconChange";

interface Props {
    onClick: () => void

}

export function ButtonChangeImage(props: Props) {

    return (
        <div className="flex flex-row bg-gray-300 rounded-full cursor-pointer px-4 py-2 select-none items-center gap-x-2
        hover:bg-gray-200 active:bg-gray-100"
             onClick={props.onClick}>
            <IconChange/>
            <span className="font-bold">Изменить</span>
        </div>
    )
}