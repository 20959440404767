interface Props {
    onClick: () => void

}

export function ButtonPublish(props: Props) {

    return (
        <div className="bg-gray-300 rounded-full cursor-pointer px-4 py-2 select-none
        hover:bg-gray-200 active:bg-gray-100"
             onClick={props.onClick}>
            <span className="font-bold">Опубликовать</span>
        </div>
    )
}