import {ButtonToolbarMenu} from "@core/ui/button/ButtonToolbarMenu";
import {ButtonHome} from "@core/ui/button/ButtonHome";
import {SearchBar} from "@core/ui/search/SearchBar";
import {ButtonSignIn} from "@anon/core/ui/button/ButtonSignIn";

export function Toolbar(){

    return (
        <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-x-2">
                <ButtonToolbarMenu/>
                <ButtonHome/>
            </div>
            <div className="w-2/5">
                <SearchBar/>
            </div>
            <div className="">
                <ButtonSignIn/>
            </div>
        </div>
    )
}