import {Outlet} from "react-router-dom";
import {Navbar} from "@admin/feature/main/ui/navbar/Navbar";
import {Toolbar} from "@admin/feature/main/ui/toolbar/Toolbar";

export function MainPage() {

    return (
        <div className="flex flex-col py-2 px-4 gap-y-2 h-screen">
            <Toolbar/>
            <div className="flex flex-row gap-x-6 w-ful h-full overflow-hidden">
                <div className="h-full w-56 overflow-y-auto">
                    <Navbar/>
                </div>
                <div className="h-full w-full overflow-hidden">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}