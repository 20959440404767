export abstract class NetworkBuilder {

    protected abstract baseUrl: string;
    protected handlerResponse: (response: Response) => Response = (response) => {
        return response
    }

    protected headers: Headers = new Headers();

    setBaseUrl(baseUrl: string): NetworkBuilder {
        this.baseUrl = baseUrl
        this.headers.set("Host", baseUrl)
        return this
    }

    setHeaders(action: (headers: Headers) => void): NetworkBuilder {
        action(this.headers)
        return this
    }

    create<T>(ClientClass: new (baseUrl: string, headers: Headers, handlerResponse: (response: Response) => Response) => T): T {
        return new ClientClass(this.baseUrl, this.headers, this.handlerResponse);
    }
}