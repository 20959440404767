import {Route, Routes} from "react-router-dom";
import {AnimListPage} from "@admin/feature/moderation-anim/ui/AnimListPage";
import {AnimPage} from "@admin/feature/moderation-anim/ui/AnimPage";

export function ModerationAnimFeature(){

    return(
        <Routes>
            <Route path="/" element={<AnimListPage/>}/>
            <Route path=":id" element={<AnimPage/>}/>
        </Routes>
    )
}