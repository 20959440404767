import {Route, Routes} from "react-router-dom";
import {SignOutPage} from "@app/feature/sign-out/ui/SignOutPage";

export function SignOutFeature() {

    return (
        <Routes>
            <Route path="/" element={<SignOutPage/>}/>
        </Routes>
    )
}