import {NetworkBuilder} from "@core/network/builder/NetworkBuilder";
import {Env} from "@core/env/Env";
import {EnvVariable} from "@core/env/EnvVariable";


export class NetworkBuilderClient extends NetworkBuilder {

    protected baseUrl: string = Env.get(EnvVariable.API_URL);

    protected headers: Headers = new Headers({
        'Host': 'https://www.sakovkid.com',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Env.get(EnvVariable.API_KEY)}`,
    });

}