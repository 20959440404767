import {VideoModel} from "@admin/feature/video/domain/model/VideoModel";
import {IconAccount} from "@core/ui/icon/IconAccount";
import {ImageMemTok} from "@core/ui/image/ImageMemTok";
import {useInView} from "react-intersection-observer";

interface Props {
    item: VideoModel,
    onClickItem: (item: VideoModel) => void
}

export function VideoItem(props: Props) {

    const {ref, inView} = useInView({threshold: 0.1, triggerOnce: true})

    return (
        <div ref={ref} className="w-1/3 cursor-pointer"
             onClick={() => props.onClickItem(props.item)}>
            {inView ?
                <div className="flex flex-col m-2 gap-y-2">
                    <div className="flex flex-col relative">
                        <ImageMemTok url={props.item.image_url}/>
                        <div className="absolute bottom-0 right-0 px-1 m-1 bg-black rounded-lg">
                            <span className="text-white">00:30</span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-2">
                        <div>
                            <IconAccount/>
                        </div>
                        <div className="flex flex-col">
                            <div className="font-bold">
                                <span>{props.item.name}</span>
                            </div>
                            <div className="flex flex-col text-gray-400">
                                <div>
                                    <span>Автор мема</span>
                                </div>
                                <div className="">
                                    <span>124К просмотров • 2 часа назад</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className="h-64">Ожидание</div>
            }
        </div>
    )
}