import {NetworkBuilder} from "@core/network/builder/NetworkBuilder";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {Env} from "@core/env/Env";
import {EnvVariable} from "@core/env/EnvVariable";

export class NetworkBuilderClient extends NetworkBuilder {

    constructor(
        private readonly userStorage: UserStorage
    ) {
        super();
    }

    static init = (): NetworkBuilder =>
        new NetworkBuilderClient(
            new UserStorageImpl()
        )


    protected baseUrl: string = Env.get(EnvVariable.API_URL);

    protected headers: Headers = new Headers({
        'Host': 'https://www.sakovkid.com',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userStorage.getToken()?.access}`,
    });
}