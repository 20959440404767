import {VideoResponse} from "@admin/core/network/client/video/model/response/VideoResponse";
import {VideoNetworkModel} from "@admin/feature/video/data/network/model/VideoNetworkModel";
import {VideoAuthorResponse} from "@admin/core/network/client/video/model/response/VideoAuthorResponse";
import {VideoAuthorNetworkModel} from "@admin/feature/video/data/network/model/VideoAuthorNetworkModel";
import {List} from "@core/list/List";
import {VideoLanguageNetworkModel} from "@admin/feature/video/data/network/model/VideoLanguageNetworkModel";
import {MemeLanguageResponse} from "@admin/core/network/client/video/model/response/MemeLanguageResponse";

export const toNetModelVideoResponse = (data: VideoResponse): VideoNetworkModel =>
    new VideoNetworkModel(
        data.id,
        data.name,
        data.image_url,
        data.video_url,
        toNetModelVideoLanguageResponse(data.language)
    )

export const toNetModelVideoAuthorResponse = (data: VideoAuthorResponse): VideoAuthorNetworkModel =>
    new VideoAuthorNetworkModel(
        data.id,
        data.name,
        data.avatar_url,
    )

export const toNetModelVideoLanguageResponse = (data: MemeLanguageResponse): VideoLanguageNetworkModel => {
    switch (data) {
        case MemeLanguageResponse.RU:
            return VideoLanguageNetworkModel.RU
        case MemeLanguageResponse.EN:
            return VideoLanguageNetworkModel.RU
    }
}

export const toNetModelListVideoResponse = (data: List<VideoResponse>): List<VideoNetworkModel> =>
    data.map(it => toNetModelVideoResponse(it))