import {AuthInteractor} from "@anon/feature/auth/domain/AuthInteractor";
import {AuthRepository} from "@anon/feature/auth/data/repository/AuthRepository";
import {AuthRepositoryImpl} from "@anon/feature/auth/data/repository/AuthRepositoryImpl";
import {ExtPromise} from "@core/network/promise/ExtPromise";

export class AuthInteractorImpl implements AuthInteractor {


    constructor(
        private repository: AuthRepository = new AuthRepositoryImpl()
    ) {
    }

    auth = (login: string, password: string): ExtPromise<any> =>
        this.repository.auth(login, password)

}