export class Router {

    to(page: Page): void {
        window.location.replace(page)
    }
}

export enum Page {
    Root = "/",
    Auth = "/auth",
    AuthSignIn = "/auth/sign-in",
    AuthRecovery = "/auth/recovery",
}