import {UserStorage} from "./UserStorage";
import {BaseCookieStorage} from "@core/storage/BaseCookieStorage";
import {AppType} from "@core/storage/user/model/AppType";
import {UserToken} from "@core/storage/user/model/UserToken";
import {Profile} from "@core/storage/user/model/Profile";

const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY'
const PROFILE_KEY = 'PROFILE_KEY'
const APP_API_KEY = 'APP_API_KEY'

export class UserStorageImpl extends BaseCookieStorage implements UserStorage {

    storage_name = 'USER_STORAGE'
    keys = [PROFILE_KEY, AUTH_TOKEN_KEY, APP_API_KEY]

    getToken(): UserToken | null {
        const userToken = this.getItem(AUTH_TOKEN_KEY)
        return userToken ? JSON.parse(userToken) : null;
    }

    setToken(token: UserToken): void {
        this.setItem(AUTH_TOKEN_KEY, JSON.stringify(token))
    }

    isAuth(): boolean {
        return this.getToken() != null;
    }

    getAppApi(): AppType {
        const appType = this.getItem(APP_API_KEY)
        return appType ? JSON.parse(appType) as AppType : AppType.ANON
    }

    setAppApi(appApi: AppType): void {
        this.setItem(APP_API_KEY, JSON.stringify(appApi))
    }

    getProfile(): Profile | null {
        const profile = this.getItem(PROFILE_KEY)
        return profile ? JSON.parse(profile) as Profile : null
    }
    setProfile(profile: Profile): void {
        this.setItem(PROFILE_KEY, JSON.stringify(profile))
    }


}