import {cloneDeep, includes} from "lodash";

export class List<T> extends Array<T> {

    constructor(...items: Array<T>) {
        super(...items);
    }

    static listOf<T>(...items:Array<T>): List<T> {
        return new this(...items)
    }

    size(): number {
        return this.length
    }

    get(index: number): T {
        return this[index]
    }

    map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): List<U> {
        return super.map(callbackfn, thisArg) as List<U>;
    }

    contains(element: T): boolean {
        return includes(this, element)
    }

    isEmpty(): boolean {
        return this.length === 0;
    }

    copy(): List<T> {
        return cloneDeep(this)
    }
}