import React from 'react';
import {Route, Routes} from "react-router-dom";
import {AppContextProvider} from "@app/core/context/AppContextProvider";
import {MainFeature} from "@app/feature/main/MainFeature";
import {SignOutFeature} from "@app/feature/sign-out/SignOutFeature";

export function App() {

    return (
        <AppContextProvider>
            <Routes>
                <Route path="/profile/sign-out" element={<SignOutFeature/>}/>
                <Route path="/*" element={<MainFeature/>}/>
            </Routes>
        </AppContextProvider>
    )
}