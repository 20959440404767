export function IconSound() {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_54)">
                <path
                    d="M9 2V11.38C8.27 10.54 7.2 10 6 10C3.79 10 2 11.79 2 14C2 16.21 3.79 18 6 18C8.21 18 10 16.21 10 14V6H16V2H9ZM6 17C4.34 17 3 15.66 3 14C3 12.34 4.34 11 6 11C7.66 11 9 12.34 9 14C9 15.66 7.66 17 6 17ZM15 5H10V3H15V5Z"
                    fill="#030303"/>
            </g>
            <defs>
                <clipPath id="clip0_3_54">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}