import {Route, Routes} from "react-router-dom";
import {MainPage} from "@admin/feature/main/ui/MainPage";
import {VideoFeature} from "@admin/feature/video/VideoFeature";
import {SoundFeature} from "@admin/feature/sound/SoundFeature";
import {AnimFeature} from "@admin/feature/anim/AnimFeature";
import {ResultFeature} from "@admin/feature/result/ResultFeature";
import {ModerationVideoFeature} from "@admin/feature/moderation-video/ModerationVideoFeature";
import {ModerationSoundFeature} from "@admin/feature/moderation-sound/ModerationSoundFeature";
import {ModerationAnimFeature} from "@admin/feature/moderation-anim/ModerationAnimFeature";

export function MainFeature() {

    return (
        <Routes>
            <Route path="/" element={<MainPage/>}>
                <Route path="/video/*" element={<VideoFeature/>}/>
                <Route path="/sound/*" element={<SoundFeature/>}/>
                <Route path="/anim/*" element={<AnimFeature/>}/>
                <Route path="/result/*" element={<ResultFeature/>}/>
                <Route path="/moderation/video/*" element={<ModerationVideoFeature/>}/>
                <Route path="/moderation/sound/*" element={<ModerationSoundFeature/>}/>
                <Route path="/moderation/anim/*" element={<ModerationAnimFeature/>}/>
            </Route>
        </Routes>
    )
}