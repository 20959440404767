import {useVideoListViewModel} from "@admin/feature/video/vm/VideoListViewModel";
import {VideoList} from "@admin/feature/video/ui/component/list/VideoList";

export function VideoListPage() {

    const viewModel = useVideoListViewModel()

    return (
        <div className="flex flex-col w-full h-full gap-y-2 overflow-y-auto">

            <div className="flex flex-row gap-x-2 p-2">
                <div className="w-fit px-2 py-1 rounded-lg bg-gray-300 hover:bg-gray-500">
                    <span>Русские</span>
                </div>
                <div className="w-fit px-2 py-1 rounded-lg bg-black hover:bg-gray-500 text-white">
                    <span>Англиские</span>
                </div>
            </div>

            <VideoList scrollRef={viewModel.ref} list={viewModel.videoList} onClickItem={viewModel.onClickItem}/>

        </div>
    )
}