import {NetworkSuccess} from "./NetworkSuccess";
import {NetworkError} from "./NetworkError";

export class NetworkResponse<T> {

    _data: NetworkSuccess<T>
    _error: NetworkError

    constructor(data: NetworkSuccess<T>, error: NetworkError) {
        this._data = data
        this._error = error
    }

    throwIfError(): T {
        if (this._data !== undefined && this._data !== null) {
            return this._data as T
        } else {
            throw new Error(this._error.message)
        }
    }

}
