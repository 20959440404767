import {useParams} from "react-router-dom";
import {VideoInteractor} from "@admin/feature/video/domain/VideoInteractor";
import {useEffect, useMemo, useState} from "react";
import {VideoInteractorImpl} from "@admin/feature/video/domain/VideoInteractorImpl";
import {VideoModel} from "@admin/feature/video/domain/model/VideoModel";

export interface State<T> {
    data?: T | null,
    loading: boolean,
    error?: Error | null
}

export function useVideoViewModel() {

    let {id} = useParams()
    const videoId = Number(id)

    const interactor: VideoInteractor = useMemo(() => new VideoInteractorImpl(), [])

    const [state, setState]
        = useState<State<VideoModel>>({data: null, error: null, loading: true})

    useEffect(() => {
        interactor.get(videoId)
            .then(it => setState({error: null, loading: false, data: it}))
            .catch(error => setState((prevState) => {
                return {error: error, loading: prevState.loading, data: prevState.data}
            }))
    }, []);

    return {
        state
    }
}