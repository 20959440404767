import {MemeClient} from "@admin/core/network/client/video/MemeClient";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {VideoResponse} from "@admin/core/network/client/video/model/response/VideoResponse";
import {BaseClient} from "@core/network/builder/BaseClient";
import {List} from "@core/list/List";

export class MemeClientImpl extends BaseClient implements MemeClient {
    readAll = (page: number): ExtPromise<List<VideoResponse>> =>
        this.get<Array<VideoResponse>>("meme/list?page=" + page)
            .then(it => List.listOf(...it))

    read = (id: number): ExtPromise<VideoResponse> =>
        this.get<VideoResponse>("meme?id=" + id)

}