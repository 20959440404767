import {SignOutInteractor} from "@app/feature/sign-out/domain/SignOutInteractor";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {SignOutRepository} from "@app/feature/sign-out/data/SignOutRepository";
import {SignOutRepositoryImpl} from "@app/feature/sign-out/data/SignOutRepositoryImpl";

export class SignOutInteractorImpl implements SignOutInteractor {


    constructor(
        private readonly repository: SignOutRepository = new SignOutRepositoryImpl()
    ) {
    }

    signOut = (): ExtPromise<any> =>
        this.repository.signOut()

}