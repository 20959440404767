import {useEffect, useState} from "react";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {CircleProgressBar} from "@core/ui/progress-bar/CircleProgressBar";
import {isEmpty} from "@core/extension/Extensions";
import ReactPlayer from "react-player";

enum State {download, success, error}

interface Props {
    url: string
}

export function VideoMemTok(props: Props) {

    const [state, setState] = useState(State.download)
    const [video, setVideo] = useState<{ blob:string,type:string }>()

    useEffect(() => {

        const headers: Headers = new Headers({
            'Authorization': `Bearer ${new UserStorageImpl().getToken()?.access}`,
        });
        let options = {
            method: 'GET',
            headers: headers
        };
        fetch(props.url, options)
            .then(it => {
                if (!it.ok) {
                    throw new Error('Network response was not ok');
                }

                return {blob:it.blob(),type:it.headers.get('Content-Type')};
            })
            .then(async it => setVideo({blob: URL.createObjectURL(await it.blob), type: it.type!}))

    }, [props]);

    useEffect(() => {
        if (video === undefined) {
            setState(State.download)
        } else if (video.blob === ""){
            setState(State.error)
        } else {
            setState(State.success)
        }
    }, [video]);

    return (
        <div className="flex w-full rounded-lg items-center justify-center bg-gray-100">
            {
                state === State.success ?
                    <ReactPlayer className=""
                                 width="100%"
                                 height="100%" url={video?.blob} playing={true} controls={true}/> :
                    state === State.download ? <CircleProgressBar/> : <div><span>Ошибка загрузки изображения</span></div>
            }
        </div>
    )
}