import {ReactNode} from "react";

interface Props {
    title: string,
}

export function TitleNavbar(props: Props) {

    return (
        <div className="flex flex-row w-full gap-x-2 rounded-lg p-2 select-none">
            <span className="font-bold">{props.title}</span>
        </div>
    )
}