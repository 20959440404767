import {IconNotFoundResult} from "@core/ui/icon/IconNotFoundResult";

export function ResultNotFoundPage(){

    return (
        <div className="flex flex-col items-center justify-center">
            <div>
                <span>Результатов не найдено</span>
            </div>
            <div className="w-64 h-64">
                <IconNotFoundResult/>
            </div>
        </div>
    )
}