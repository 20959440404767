import {ProfileRole} from "@core/storage/user/model/ProfileRole";

export class Profile {
    constructor(
        public readonly name: string,
        public readonly surname: string,
        public readonly role: ProfileRole,
        public readonly patronymic?: string,
        public readonly avatarUrl?: string,
    ) {
    }
}