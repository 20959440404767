import {ButtonNavbar} from "@admin/feature/main/ui/navbar/ButtonNavbar";
import {IconVideo} from "@core/ui/icon/IconVideo";
import {IconSound} from "@core/ui/icon/IconSound";
import {IconAnimation} from "@core/ui/icon/IconAnimation";
import {TitleNavbar} from "@admin/feature/main/ui/navbar/TitleNavbar";
import {IconLogout} from "@core/ui/icon/IconLogout";

export function Navbar(){

    return (
        <div className="flex flex-col h-full overflow-y-auto">
            <div className="flex flex-col w-full">
                <ButtonNavbar title="Видео" icon={<IconVideo/>} navPath="/video"/>
                <ButtonNavbar title="Звуки" icon={<IconSound/>} navPath="/sound"/>
                <ButtonNavbar title="Анимации" icon={<IconAnimation/>} navPath="/anim"/>
            </div>
            <hr className="my-4"/>
            <div className="flex flex-col w-full">
                <TitleNavbar title="Ждут модерации"/>
                <ButtonNavbar title="Видео" icon={<IconVideo/>} navPath="/moderation/video"/>
                <ButtonNavbar title="Звуки" icon={<IconSound/>} navPath="/moderation/sound"/>
                <ButtonNavbar title="Анимации" icon={<IconAnimation/>} navPath="/moderation/anim"/>
            </div>
            <hr className="my-4"/>
            <div className="flex flex-col w-full">
                <ButtonNavbar title="Выход" icon={<IconLogout/>} navPath="/profile/sign-out"/>
            </div>
        </div>
    )
}