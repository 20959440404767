import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {useEffect, useMemo, useState} from "react";
import {ProfileRole} from "@core/storage/user/model/ProfileRole";

export function useAppViewModel(){

    const userStorage = useMemo(() => new UserStorageImpl(), []);

    const [role, setRole] = useState<ProfileRole>()

    useEffect(() => {
        const role = userStorage.getProfile()?.role ?? ProfileRole.ANON
        setRole(role);
    }, [userStorage])

    return {
        role
    }
}