import {Route, Routes} from "react-router-dom";
import {SoundListPage} from "@admin/feature/sound/ui/page/SoundListPage";
import {SoundPage} from "@admin/feature/sound/ui/page/SoundPage";

export function SoundFeature(){

    return (
        <Routes>
            <Route path="/" element={<SoundListPage/>}/>
            <Route path=":id" element={<SoundPage/>}/>
        </Routes>
    )
}