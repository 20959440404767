import {Route, Routes} from "react-router-dom";
import {AnimListPage} from "@admin/feature/anim/ui/page/AnimListPage";
import {AnimPage} from "@admin/feature/anim/ui/page/AnimPage";

export function AnimFeature(){

    return (
        <Routes>
            <Route path="/" element={<AnimListPage/>}/>
            <Route path=":id" element={<AnimPage/>}/>
        </Routes>
    )
}