import {AuthRepository} from "@anon/feature/auth/data/repository/AuthRepository";
import {AuthNetworkClient} from "@anon/feature/auth/data/network/AuthNetworkClient";
import {AuthNetworkClientImpl} from "@anon/feature/auth/data/network/AuthNetworkClientImpl";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {UserStorage} from "@core/storage/user/UserStorage";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {UserToken} from "@core/storage/user/model/UserToken";
import {Profile} from "@core/storage/user/model/Profile";
import {ProfileRole} from "@core/storage/user/model/ProfileRole";
import {tokenNetworkModel_toProfile, userNetworkModel_toProfile} from "@anon/feature/auth/data/DataMapper";

export class AuthRepositoryImpl implements AuthRepository {

    constructor(
        private networkClient: AuthNetworkClient = new AuthNetworkClientImpl(),
        private userStorage: UserStorage = new UserStorageImpl()
    ) {
    }

    auth = (login: string, password: string): ExtPromise<any> =>
        this.networkClient.auth(login, password)
            .onEach(it => {
                this.userStorage.setToken(tokenNetworkModel_toProfile(it.token))
                this.userStorage.setProfile(userNetworkModel_toProfile(it.user))
            })

}