import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {SignInPage} from "@anon/feature/auth/ui/SignInPage";
import {SignUpPage} from "@anon/feature/auth/ui/SignUpPage";
import {RecoveryPage} from "@anon/feature/auth/ui/RecoveryPage";

export function FeatureAuth() {

    return (
        <Routes>
            <Route path="/sign-in" element={<SignInPage/>}/>
            <Route path="/sign-up" element={<SignUpPage/>}/>
            <Route path="/recovery" element={<RecoveryPage/>}/>
            <Route path="/*" element={<Navigate to={"sign-in"}/>}/>
        </Routes>
    );
}