import {List} from "@core/list/List";
import {VideoModel} from "@admin/feature/video/domain/model/VideoModel";
import {VideoItem} from "@admin/feature/video/ui/component/list/VideoItem";

interface Props {
    list: List<VideoModel>
    scrollRef: (node?: Element | null) => void
    onClickItem: (item: VideoModel) => void
}

export function VideoList(props: Props) {

    return (
        <div className="flex flex-wrap w-full h-full overflow-y-auto">
            {
                props.list.map(it => <VideoItem key={it.id} item={it} onClickItem={props.onClickItem}/>)
            }
            <div ref={props.scrollRef} hidden={props.list.isEmpty()}>123</div>
        </div>
    )
}