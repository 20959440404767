import {VideoNetworkClient} from "@admin/feature/video/data/network/VideoNetworkClient";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {NetworkProvider} from "@admin/core/network/NetworkProvider";
import {NetworkProviderImpl} from "@admin/core/network/NetworkProviderImpl";
import {List} from "@core/list/List";
import {toNetModelListVideoResponse, toNetModelVideoResponse} from "@admin/feature/video/data/DataMapper";
import {VideoNetworkModel} from "@admin/feature/video/data/network/model/VideoNetworkModel";

export class VideoNetworkClientImpl implements VideoNetworkClient {

    constructor(
        private readonly networkProvider: NetworkProvider = new NetworkProviderImpl()
    ) {
    }

    getList = (page: number): ExtPromise<List<VideoNetworkModel>> =>
        this.networkProvider
            .getMemeClient()
            .readAll(page)
            .then(it => toNetModelListVideoResponse(it))

    get = (id: number): ExtPromise<VideoNetworkModel> =>
        this.networkProvider
            .getMemeClient()
            .read(id)
            .then(it => toNetModelVideoResponse(it))


}
