import {VideoInteractorImpl} from "@admin/feature/video/domain/VideoInteractorImpl";
import {VideoInteractor} from "@admin/feature/video/domain/VideoInteractor";
import {RefObject, useEffect, useMemo, useRef, useState} from "react";
import {List} from "@core/list/List";
import {VideoModel} from "@admin/feature/video/domain/model/VideoModel";
import {useInView} from "react-intersection-observer";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

interface State {
    videoList: List<VideoModel>,
    loading: boolean,
    error: Error | null
}

export function useVideoListViewModel() {

    const interactor = useMemo(() => new VideoInteractorImpl(), []);

    const [page, setPage] = useState(1);
    const [videoList, setVideoList] = useState<List<VideoModel>>(List.listOf());
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const {ref, inView} = useInView({
        threshold: 1 // Порог видимости
    });

    function onClickItem(item: VideoModel) {
        window.location.replace("/video/"+item.id)
    }

    useEffect(() => {
        console.log(page)
        interactor.getList(page)
            .then((data) => {
                setVideoList((prevList) => List.listOf(...prevList, ...data));
            })
            .catch((error) => {
                console.error('Ошибка загрузки данных:', error);
            });
    }, [page]);

    useEffect(() => {
        console.log(inView)
        if (inView) {
            setPage(prevPage => prevPage + 1)
        }
    }, [inView]);

    return {
        videoList,
        ref,
        onClickItem
    };
}