import {VideoLanguageModel} from "@admin/feature/video/domain/model/VideoLanguageModel";

export class VideoModel {

    constructor(
        readonly id: number,
        readonly name: string,
        readonly image_url: string,
        readonly video_url: string,
        readonly language: VideoLanguageModel
    ) {
    }
}