import {VideoInteractor} from "@admin/feature/video/domain/VideoInteractor";
import {VideoModel} from "@admin/feature/video/domain/model/VideoModel";
import {VideoRepository} from "@admin/feature/video/data/VideoRepository";
import {VideoRepositoryImpl} from "@admin/feature/video/data/VideoRepositoryImpl";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {toModelListVideoNetworkModel, toModelVideoNetworkModel} from "@admin/feature/video/domain/DomainMapper";
import {List} from "@core/list/List";

export class VideoInteractorImpl implements VideoInteractor {

    constructor(
        private readonly repository: VideoRepository = new VideoRepositoryImpl()
    ) {
    }

    getList = (page: number): ExtPromise<List<VideoModel>> =>
        this.repository
            .getList(page)
            .then(it => toModelListVideoNetworkModel(it))

    get = (id: number): ExtPromise<VideoModel> =>
        this.repository
            .get(id)
            .then(it => toModelVideoNetworkModel(it))


}