export class StringUtil {
    static isValidEmail(value: string): boolean {
        return !!value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    static isValidPhone(value: string): boolean {
        return !!value.match(/^\d+$/)
    }
}
