import {useSignInViewModel} from "@anon/feature/auth/vm/SignInViewModel";
import {useSignOutViewModel} from "@app/feature/sign-out/vm/SignOutViewModel";

export function SignOutPage() {

    const interactor = useSignOutViewModel()

    return (
        <div></div>
    )
}