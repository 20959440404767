export interface PasswordInputFieldModel{
    onChange: (value: string) => void
}
export function PasswordInputField(parameter:PasswordInputFieldModel) {

    return (
            <input id="password"
                   name="password"
                   type="password"
                   autoComplete="current-password"
                   required
                   className="relative block w-full appearance-none rounded-none rounded-b-md
                                            border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10
                                            focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   placeholder="Пароль"
                   onChange={(event) => {
                       parameter.onChange(event.target.value)
                   }}/>
    );
}