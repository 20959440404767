import {Route, Routes} from "react-router-dom";
import {SoundListPage} from "@admin/feature/moderation-sound/ui/SoundListPage";
import {SoundPage} from "@admin/feature/moderation-sound/ui/SoundPage";

export function ModerationSoundFeature(){

    return(
        <Routes>
            <Route path="/" element={<SoundListPage/>}/>
            <Route path=":id" element={<SoundPage/>}/>
        </Routes>
    )
}