import React, {createContext, useState} from "react";
import {initialSnackbarComponent, Snackbar, SnackbarComponent} from "@core/ui/snackbar/Snackbar";

interface Props {
    children: React.ReactNode
}

export interface AppContext {
    lang: string
    theme: string
    snackbar: SnackbarComponent
}

const initContext: AppContext = {
    lang: "",
    theme: "",
    snackbar: initialSnackbarComponent
}

const AppContext: React.Context<AppContext> = createContext<AppContext>(initContext)

export const useAppContext = () => React.useContext(AppContext)

export function AppContextProvider({children}: Props) {

    const [context, setContext] = useState<AppContext>(initContext)

    return <AppContext.Provider value={{...context}}>
        <Snackbar/>
        {children}
    </AppContext.Provider>
}