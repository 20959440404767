import Bac from "@core/ui/res/img_background.png";

export function Background() {

    return (
        <div
            className="absolute inset-0 "
            style={{backgroundImage: `url(${Bac})`}}
        />
    );
}