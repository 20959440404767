import {useEffect, useMemo} from "react";
import {SignOutInteractorImpl} from "@app/feature/sign-out/domain/SignOutInteractorImpl";
import {Page, Router} from "@app/core/router/Router";

export function useSignOutViewModel() {

    const interactor = useMemo(() => new SignOutInteractorImpl(), [])
    const router = useMemo(() => new Router(), [])

    useEffect(() => {
        interactor.signOut()
            .then(() => router.to(Page.Root))
    }, [interactor, router]);

    return {}
}