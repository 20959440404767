export class ExtPromise<T> extends Promise<T> {

    constructor(executor: (resolve: (value: (PromiseLike<T> | T)) => void, reject: (reason?: any) => void) => void) {
        super(executor);
    }

    then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onrejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null): ExtPromise<TResult1 | TResult2> {
        return super.then(onfulfilled, onrejected) as ExtPromise<TResult1 | TResult2>;
    }


    catch<TResult = never>(onrejected?: ((reason: any) => (PromiseLike<TResult> | TResult)) | undefined | null): ExtPromise<T | TResult> {
        return super.catch(onrejected) as ExtPromise<T | TResult>;
    }

    finally(onfinally?: (() => void) | undefined | null): ExtPromise<T> {
        return super.finally(onfinally) as ExtPromise<T>;
    }

    onEach(callback: (data: T) => void): ExtPromise<T> {
        return this.then((data: T) => {
            callback(data);
            return data;
        });
    }
}