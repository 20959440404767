import {IconAccount} from "@core/ui/icon/IconAccount";

interface Props {
    onClick?: () => void
}

export function ButtonSignIn(props: Props) {

    return (
        <div className="flex flex-row border rounded-full w-fit items-center p-2 gap-x-2 px-4 hover:bg-gray-50
        active:bg-gray-100">
            <IconAccount/>
            <span>Sign in</span>
        </div>
    )
}