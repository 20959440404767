import {Route, Routes} from "react-router-dom";
import {ResultNotFoundPage} from "@admin/feature/result/ui/page/ResultNotFoundPage";

export function ResultFeature(){

    return (
        <Routes>
            <Route path="/" element={<ResultNotFoundPage/>}/>
        </Routes>
    )
}