import {ExtPromise} from "@core/network/promise/ExtPromise";
import {NetworkResponse} from "@core/network/builder/response/NetworkResponse";
import {extFetch} from "@core/network/promise/ext-fetch";
import {NetworkError} from "@core/network/builder/response/NetworkError";

export abstract class BaseClient {

    protected baseUrl: string
    protected headers: Headers = new Headers()
    protected handlerResponse: (response: Response) => Response

    constructor(
        baseUrl: string,
        headers: Headers,
        handlerResponse: (response: Response) => Response
    ) {
        this.baseUrl = baseUrl
        this.headers = headers
        this.handlerResponse = handlerResponse
    }


    protected get<T>(url: string): ExtPromise<T> {
        let options = {
            method: 'GET',
            headers: this.headers
        };

        return this.call(this.baseUrl + url, options)
    }

    protected post<T>(url: string, body: {} = {}): ExtPromise<T> {
        let options = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(body)
        };

        return this.call(this.baseUrl + url, options)
    }

    protected delete<T>(url: string, body: {} = {}): ExtPromise<T> {
        let options = {
            method: 'DELETE',
            headers: this.headers,
            body: JSON.stringify(body)
        };

        return this.call(this.baseUrl + url, options)
    }

    protected put<T>(url: string, body: {} = {}): ExtPromise<T> {
        let options = {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(body)
        };

        return this.call(this.baseUrl + url, options)
    }

    private call<T>(url: string, options: RequestInit): ExtPromise<T> {
        return extFetch(url, options)
            .then(this.handlerResponse)
            .then(response => response.json())
            .then(response => new NetworkResponse<T>(response.data, response.error))
            .then(response => response.throwIfError())
            .catch(error => {
                this.handlerError(error)
                throw error
            })
    }

    private handlerError(error: NetworkError) {
        switch (error.code) {
            case 403:
                window.location.replace("")
                break
        }
    }

}