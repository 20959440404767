import {VideoRepository} from "@admin/feature/video/data/VideoRepository";
import {ExtPromise} from "@core/network/promise/ExtPromise";
import {VideoNetworkClient} from "@admin/feature/video/data/network/VideoNetworkClient";
import {VideoNetworkClientImpl} from "@admin/feature/video/data/network/VideoNetworkClientImpl";
import {VideoNetworkModel} from "@admin/feature/video/data/network/model/VideoNetworkModel";
import {List} from "@core/list/List";

export class VideoRepositoryImpl implements VideoRepository {
    constructor(
        private readonly networkClient: VideoNetworkClient = new VideoNetworkClientImpl()
    ) {
    }

    getList = (page: number): ExtPromise<List<VideoNetworkModel>> =>
        this.networkClient.getList(page)

    get = (id: number): ExtPromise<VideoNetworkModel> =>
        this.networkClient.get(id)

}