import {LogoIcon} from "@core/ui/image/LogoIcon";
import {LoginInputField} from "@anon/core/ui/inputField/LoginInputField";
import {PasswordInputField} from "@anon/core/ui/inputField/PasswordInputField";
import {TextLink} from "@core/ui/text/TextLink";
import React from "react";
import {ButtonPrimary} from "@core/ui/button/ButtonPrimary";
import {useSignInViewModel} from "@anon/feature/auth/vm/SignInViewModel";

export function SignInPage() {

    const viewModel = useSignInViewModel()

    return (
        <div className="relative px-6 pt-10 pb-8
        sm:bg-white sm:shadow-xl sm:ring-1 sm:ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
            <div className="flex flex-col py-4 px-4sm:px-6 lg:px-8 items-center justify-center min-h-full">
                <div className="m-5">
                    {/*<LogoIcon height={128} width={128}/>*/}
                </div>
                <div>
                    <h2 className="mb-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                        Войдите в свой аккаунт
                    </h2>
                </div>
                <div className="max-w-sm w-full">
                    <LoginInputField onChange={value => viewModel.onChangeLogin(value)}/>
                </div>
                <div className="max-w-sm w-full">
                    <PasswordInputField onChange={value => viewModel.onChangePassword(value)}/>
                </div>
                <div className="mt-5 w-full">
                    <ButtonPrimary title="Войти" onClick={viewModel.onClickSignIn}/>
                </div>
                <div className="mt-2">
                    <TextLink text="Забыли свой пароль" onClickListener={viewModel.onClickRecovery}/>
                </div>
            </div>
        </div>
    )
}