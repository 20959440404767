import {Route, Routes} from "react-router-dom";
import React from "react";
import {MainFeature} from "@admin/feature/main/MainFeature";

export function AppAdmin() {

    return (
        <Routes>
            <Route path="/*" element={<MainFeature/>}/>
        </Routes>
    );
}