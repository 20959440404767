import {useEffect, useState} from "react";
import {UserStorageImpl} from "@core/storage/user/UserStorageImpl";
import {CircleProgressBar} from "@core/ui/progress-bar/CircleProgressBar";
import {isEmpty} from "@core/extension/Extensions";

enum State {download, success, error}

interface Props {
    url: string
}

export function ImageMemTok(props: Props) {

    const [state, setState] = useState(State.download)
    const [image, setImage] = useState<string>()

    useEffect(() => {

        const headers: Headers = new Headers({
            'Authorization': `Bearer ${new UserStorageImpl().getToken()?.access}`,
        });
        let options = {
            method: 'GET',
            headers: headers
        };
        fetch(props.url, options)
            .then(it => {
                if (!it.ok) {
                    throw new Error('Network response was not ok');
                }
                return it.blob();
            })
            .then(it => setImage(URL.createObjectURL(it)))

    }, [props]);

    useEffect(() => {
        if (image === undefined) {
            setState(State.download)
        } else if (image === ""){
            setState(State.error)
        } else {
            setState(State.success)
        }
    }, [image]);

    return (
        <div className="flex w-full h-64 rounded-lg items-center justify-center bg-gray-100">
            {
                state === State.success ? <img className="rounded-lg w-full h-full object-cover" src={image} alt=""/> :
                    state === State.download ? <CircleProgressBar/> : <div><span>Ошибка загрузки изображения</span></div>
            }
        </div>
    )
}