import {EnvVariable} from "./EnvVariable";

export class Env {
    public static get(variable: EnvVariable): string {
        switch (variable) {
            case EnvVariable.DEBUG_MODE:
                return process.env.REACT_APP_DEBUG_MODE!;
            case EnvVariable.API_URL:
                return process.env.REACT_APP_API_URL!;
            case EnvVariable.API_KEY:
                return process.env.REACT_APP_API_KEY!;
        }
    }
}