interface Props {
    title: string,
    onClick?: () => void
}

export function ButtonPrimary(props: Props) {
    return (
        <div className="flex flex-row w-full min-h-8 bg-primary-200 rounded-lg items-center justify-center text-white
        cursor-pointer hover:bg-primary-300 active:bg-primary-400"
             onClick={props.onClick}>
            <span>{props.title}</span>
        </div>
    )
}