import {Route, Routes} from "react-router-dom";
import {VideoListPage} from "@admin/feature/moderation-video/ui/VideoListPage";
import {VideoPage} from "@admin/feature/moderation-video/ui/VideoPage";

export function ModerationVideoFeature(){

    return(
        <Routes>
            <Route path="/" element={<VideoListPage/>}/>
            <Route path=":id" element={<VideoPage/>}/>
        </Routes>
    )
}