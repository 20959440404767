import {Route, Routes} from "react-router-dom";
import {SignOutPage} from "@app/feature/sign-out/ui/SignOutPage";
import {useAppViewModel} from "@app/feature/main/vm/AppViewModel";
import {ProfileRole} from "@core/storage/user/model/ProfileRole";
import React, {ReactNode} from "react";
import {AppAdmin} from "@admin/AppAdmin";
import {AppClient} from "@client/AppClient";
import {AppAnon} from "@anon/AppAnon";
import {LoadingPage} from "@core/ui/pages/LoadingPage";

export function MainFeature() {

    const viewModel = useAppViewModel();

    function getApplication(role?: ProfileRole): ReactNode {

        return role === ProfileRole.ADMIN ? <AppAdmin/> :
            role === ProfileRole.CLIENT ? <AppClient/> :
                role === ProfileRole.ANON ? <AppAnon/> :
                    <LoadingPage/>
    }

    return (
        <Routes>
            <Route path="/*" element={getApplication(viewModel.role)}/>
        </Routes>
    )
}