import {VideoNetworkModel} from "@admin/feature/video/data/network/model/VideoNetworkModel";
import {VideoModel} from "@admin/feature/video/domain/model/VideoModel";
import {VideoAuthorNetworkModel} from "@admin/feature/video/data/network/model/VideoAuthorNetworkModel";
import {VideoAuthorModel} from "@admin/feature/video/domain/model/VideoAuthorModel";
import {List} from "@core/list/List";
import {MemeLanguageResponse} from "@admin/core/network/client/video/model/response/MemeLanguageResponse";
import {VideoLanguageNetworkModel} from "@admin/feature/video/data/network/model/VideoLanguageNetworkModel";
import {VideoLanguageModel} from "@admin/feature/video/domain/model/VideoLanguageModel";

export const toModelVideoNetworkModel = (data: VideoNetworkModel): VideoModel =>
    new VideoModel(
        data.id,
        data.name,
        data.imageUrl,
        data.videoUrl,
        toModelVideoLanguageNetworkModel(data.language)
    )

export const toModelVideoAuthorNetworkModel = (data: VideoAuthorNetworkModel): VideoAuthorModel =>
    new VideoAuthorModel(
        data.id,
        data.name,
        data.avatarUrl
    )

export const toModelVideoLanguageNetworkModel = (data: VideoLanguageNetworkModel): VideoLanguageModel => {
    switch (data) {
        case VideoLanguageNetworkModel.RU:
            return VideoLanguageModel.RU
        case VideoLanguageNetworkModel.EN:
            return VideoLanguageModel.RU
    }
}

export const toModelListVideoNetworkModel = (data: List<VideoNetworkModel>): List<VideoModel> =>
    data.map(it => toModelVideoNetworkModel(it))