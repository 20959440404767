interface Props {
    onClick: () => void

}

export function ButtonDelete(props: Props) {

    return (
        <div className="bg-black rounded-full cursor-pointer px-4 py-2 select-none
        hover:bg-gray-800 active:bg-gray-600"
             onClick={props.onClick}>
            <span className="text-white font-bold">Удалить</span>
        </div>
    )
}