import {IconSearch} from "@core/ui/icon/IconSearch";
import {useEffect, useState} from "react";

interface Props {
    value?: string,
    onClickSearch?: (value: string) => void
}

export function SearchBar(props: Props) {

    const [value, setValue] = useState("");

    function onClickSearch() {
        props.onClickSearch?.(value)
    }

    function onChangeSearch(value: string) {
        setValue(value)
    }

    return (
        <div className="flex flex-row w-full">
            <div className="w-full border rounded-l-full px-4 py-2 ">
                <input className="w-full focus:outline-none"
                       value={props.value}
                       onChange={event => onChangeSearch(event.target.value)}/>
            </div>
            <div className="flex w-16 border-t border-b border-r rounded-r-full bg-gray-50 items-center justify-center
                cursor-pointer hover:bg-gray-100 active:bg-gray-200"
                 onClick={onClickSearch}>
                <IconSearch/>
            </div>
        </div>
    )
}