import {NetworkProvider} from "@admin/core/network/NetworkProvider";
import {NetworkBuilder} from "@core/network/builder/NetworkBuilder";
import {NetworkBuilderClient} from "@admin/core/network/builder/NetworkBuilderClient";
import {MemeClientImpl} from "@admin/core/network/client/video/MemeClientImpl";
import {MemeClient} from "@admin/core/network/client/video/MemeClient";

export class NetworkProviderImpl implements NetworkProvider {

    constructor(
        private readonly builder: NetworkBuilder = NetworkBuilderClient.init()
    ) {
    }

    getMemeClient = (): MemeClient =>
        this.builder.create(MemeClientImpl)

}