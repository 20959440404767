import {InputFieldModel} from "@core/ui/inputField/model/InputFieldModel";

export interface LoginInputFieldModel{
    onChange: (value: string) => void
}

export function LoginInputField(parameter:LoginInputFieldModel) {

    return (
        <input id="email-address"
               name="email"
               type="email"
               autoComplete="email"
               required
               className="relative block w-full appearance-none rounded-none rounded-t-md border
                                             border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10
                                             focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
               placeholder="Логин"
               onChange={(event) => {
                   parameter.onChange(event.target.value)
               }}/>

    );
}