import {ExtPromise} from "@core/network/promise/ExtPromise";
import {SignOutNetworkClient} from "@app/feature/sign-out/data/network/SignOutNetworkClient";
import {NetworkProvider} from "@app/core/network/NetworkProvider";
import {NetworkProviderImpl} from "@app/core/network/NetworkProviderImpl";

export class SignOutNetworkClientImpl implements SignOutNetworkClient {

    constructor(
        private readonly networkProvider: NetworkProvider = new NetworkProviderImpl()
    ) {
    }

    signOut = (): ExtPromise<any> =>
        this.networkProvider
            .getAuthClient()
            .signOut()

}