import {useVideoViewModel} from "@admin/feature/video/vm/VideoViewModel";
import {ImageMemTok} from "@core/ui/image/ImageMemTok";
import {VideoMemTok} from "@core/ui/image/VideoMemTok";
import {IconAccount} from "@core/ui/icon/IconAccount";
import {ButtonPublish} from "@admin/core/ui/button/ButtonPublish";
import {ButtonDelete} from "@admin/core/ui/button/ButtonDelete";
import {ButtonChangeImage} from "@admin/core/ui/button/ButtonChangeImage";

export function VideoPage() {

    const viewModel = useVideoViewModel()

    return (
        <div className="flex flex-col h-full overflow-y-auto gap-y-2">
            {viewModel.state.data ?
                <>
                    <div className="w-full self-center rounded-lg">
                        <VideoMemTok url={viewModel.state.data.video_url}/>
                    </div>
                    <div className="flex flex-col">
                        <div>
                            <span className="font-bold">{viewModel.state.data.name}</span>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-row gap-x-2">
                            <div>
                                <IconAccount/>
                            </div>
                            <div className="flex flex-col">
                                <div><span>Автор</span></div>
                                <div><span>16.7К subsc</span></div>
                            </div>
                            <div className="ml-4">
                                <ButtonPublish onClick={() => {
                                }}/>
                            </div>
                            <div>
                                <ButtonDelete onClick={() => {
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-96 self-center p-4 bg-gray-100 rounded-lg gap-y-2">
                        <ImageMemTok url={viewModel.state.data.image_url}/>
                        <div className="w-fit"> <ButtonChangeImage onClick={()=>{}}/></div>
                    </div>
                </> : null}

        </div>
    )
}