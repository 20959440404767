import {ReactNode, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";

interface Props {
    title: string,
    icon: ReactNode
    navPath: string
}

export function ButtonNavbar(props: Props) {

    const path = useLocation()

    console.log(path.pathname)
    console.log(props.navPath)

    return (
        <Link className={`flex flex-row w-full gap-x-2 rounded-lg p-2 select-none cursor-pointer
        hover:bg-gray-100 active:bg-gray-200
        ${path.pathname.startsWith(props.navPath) ? "bg-gray-200" : "bg-white"}`}
              to={props.navPath}>
            {props.icon}
            <span>{props.title}</span>
        </Link>
    )
}