import {IconLogo} from "@core/ui/icon/IconLogo";

interface Props {
    onClick?: () => void
}

export function ButtonHome(props: Props) {
    return (
        <div className="flex flex-row cursor-pointer p-2 gap-x-2 items-center"
             onClick={props.onClick}>
            <IconLogo/>
            <span>MemTok</span>
        </div>
    )
}