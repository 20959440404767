import {VideoLanguageNetworkModel} from "@admin/feature/video/data/network/model/VideoLanguageNetworkModel";

export class VideoNetworkModel {

    constructor(
        readonly id: number,
        readonly name: string,
        readonly imageUrl: string,
        readonly videoUrl: string,
        readonly language: VideoLanguageNetworkModel
    ) {
    }
}