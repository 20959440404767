import {NetworkBuilder} from "@core/network/builder/NetworkBuilder";
import {NetworkProvider} from "@app/core/network/NetworkProvider";
import {NetworkBuilderClient} from "@app/core/network/builder/NetworkBuilderClient";
import {AuthClientImpl} from "@app/core/network/client/auth/AuthClientImpl";
import {AuthClient} from "@app/core/network/client/auth/AuthClient";

export class NetworkProviderImpl implements NetworkProvider {

    constructor(
        private readonly builder: NetworkBuilder = new NetworkBuilderClient()
    ) {
    }

    getAuthClient = (): AuthClient =>
        this.builder.create(AuthClientImpl)


}