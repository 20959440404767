import {useMemo, useState} from "react";
import {AuthInteractor} from "@anon/feature/auth/domain/AuthInteractor";
import {AuthInteractorImpl} from "@anon/feature/auth/domain/AuthInteractorImpl";
import {Page, Router} from "@anon/core/router/Router";

export function useSignInViewModel() {

    const router = new Router()

    const interactor: AuthInteractor = useMemo(() => new AuthInteractorImpl(), []);
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    function onClickSignIn(){
        interactor.auth(login, password)
            .then(() => router.to(Page.Root))
            .catch()
    }

    function onChangeLogin(value: string) {
        setLogin(value)
    }

    function onChangePassword(value: string) {
        setPassword(value)
    }

    function onClickRecovery(){
        router.to(Page.AuthRecovery)
    }

    return {
        login, onChangeLogin,
        password, onChangePassword,
        onClickSignIn,
        onClickRecovery
    }
}